.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: var(--platform-accent);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: var(--card-border-radius);
}

.react-grid-item:hover {
  outline: 5px solid var(--platform-accent);
  border-radius: var(--card-border-radius);
}

.react-grid-item:hover>.react-resizable-handle,
.react-resizable-handle:focus {
  opacity: 1
}

.react-grid-item>.react-resizable-handle {
  opacity: 0;
  position: absolute;
  margin-bottom: -5px;
  margin-right: -5px;
  width: 15px;
  height: 15px;
  background: var(--background);
  padding: 5px;
  border-radius: 999px;
  z-index: 3;
  outline: 5px solid var(--platform-accent);
}

.react-resizable-hide>.react-resizable-handle {
  display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
